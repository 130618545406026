import { useEffect } from 'react';

/**
 * This custom hook is too avoid verbose code in multiple children
 * components.
 * @param {*} id if it does not exist, we return the standard error message from atomic
 * @param {Function} handleError will call ErrorBoundary wrapper
 * @param {object} specificError if we wish to specify the error displayed
 * in the oops component.
 */
export function useError(id, handleError, specificError) {
  useEffect(
    () => {
      if (!id) {
        handleError(specificError);
      }
    },
    [id, handleError, specificError]
  );
}
