import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { useError } from 'utils/hooks';

import { withGqlClient, withUser, withSpecificError } from 'wrappers';
import { ROUTE_MEMBERS } from 'constant/routes';

import { LoaderTraceLogo } from '@stratumn/atomic';
import Member from './member';

const query = graphql`
  query memberRootQuery(
    $userRowId: BigInt!
    $organizationFilter: OrganizationFilter
    $memberFilter: MemberFilter
    $teamFilter: TeamFilter
  ) {
    userByRowId(rowId: $userRowId) {
      ...member_member
        @arguments(
          organizationFilter: $organizationFilter
          memberFilter: $memberFilter
          teamFilter: $teamFilter
        )
    }
  }
`;

export const Renderer = withSpecificError(
  ({
    error,
    props: data,
    errorContext: { handleError },
    match: { params }
  }) => {
    if (!data && !error) return <LoaderTraceLogo />;

    let userRowId;

    if (data) userRowId = data.userByRowId;

    const specificError = {
      description: `the member with the id ${params.id} does not exist.`,
      label: "take me to the members' page",
      path: ROUTE_MEMBERS
    };

    useError(userRowId, handleError, specificError);

    if (error) return null;
    if (!userRowId) return null;

    return <Member member={userRowId} />;
  }
);

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const MemberRoot = props => {
  const { environment, selectedOrganization, memberRowId } = props;
  const variables = {};

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }

  const { rowId } = selectedOrganization;

  variables.userRowId = memberRowId;
  variables.organizationFilter = { rowId: { equalTo: rowId } };
  variables.memberFilter = { userId: { equalTo: memberRowId } };
  variables.teamFilter = { organizationId: { equalTo: rowId } };

  // We need to make sure that the location props are passed to child components correctly.
  // https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/react-router/docs/guides/blocked-updates.md
  const RendererWithRouter = withRouter(Renderer);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={RendererWithRouter}
    />
  );
};

MemberRoot.propTypes = {
  environment: PropType.object.isRequired,
  memberRowId: PropType.string.isRequired,
  selectedOrganization: PropType.object
};

MemberRoot.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(MemberRoot);
