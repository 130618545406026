import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';

import { SpanType, withSpanAsync } from 'tracing';
import { editAccountMembershipOrg } from 'mutations';

import {
  Directory,
  DirectoryRow,
  Switch,
  withSnackbarsContext
} from '@stratumn/atomic';

import { PendingInvite, UserAvatarLink } from 'components/ui';
import { withGqlClient, withUser } from 'wrappers';

import {
  ADMIN_GRANT,
  ADMIN_REVOKE,
  MEMBER_KEY,
  LEADER_REVOKE,
  LEADER_GRANT
} from 'constant/types';
import pluralize from 'utils/pluralize';
import styles from './people.style';

/**
 * Compare function to use with array.sort to sort members edges
 * @param {object} edgeA The first edge user element for comparison.
 * @param {object} edgeB The second edge user element for comparison.
 * @returns -1, 1 or 0 depending on the edges user.name alphabetical order.
 */
const usersAlphabeticalCompareFn = (edgeA, edgeB) => {
  if (edgeA.node.user.name.toLowerCase() < edgeB.node.user.name.toLowerCase()) {
    return -1;
  }
  if (edgeA.node.user.name.toLowerCase() > edgeB.node.user.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

export class People extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired,
    environment: PropType.object.isRequired,
    organization: PropType.object.isRequired,
    user: PropType.object.isRequired
  };

  switchRole = (roleType, isRole, userId) => {
    const {
      environment,
      organization: { account, rowId: organizationRowId },
      user: { rowId }
    } = this.props;

    const adminRole = roleType === 'ADMIN';
    const leaderRole = roleType === 'LEADER';
    const isAdmin = adminRole && isRole;
    const isLeader = leaderRole && isRole;

    const adminAction = adminRole && isAdmin ? ADMIN_REVOKE : ADMIN_GRANT;
    const leaderAction = leaderRole && isLeader ? LEADER_REVOKE : LEADER_GRANT;

    const transactionName = isAdmin
      ? `${adminAction}Admin`
      : `${leaderAction}Leader`;
    const role = roleType;
    const action = isAdmin ? adminAction : leaderAction;

    withSpanAsync(transactionName, SpanType.processing, async () => {
      await editAccountMembershipOrg(
        environment,
        organizationRowId,
        role,
        userId,
        account.id,
        account.rowId,
        action,
        MEMBER_KEY // Fragment connection key in order to update the relay store
      );

      if (userId === rowId) window.location.reload();
    });
    return {};
  };

  renderUserRow = (edge, index) => {
    const {
      admin,
      leader,
      user: { id, rowId, name, email, pending, inviteExpired, avatar, teams }
    } = edge;

    const {
      classes,
      organization: {
        id: organizationId,
        rowId: organizationRowId,
        account,
        canUpdate,
        isLeader
      },
      user: { isSuperuser }
    } = this.props;

    return (
      <div className={classes.row} key={email}>
        <DirectoryRow
          key={email}
          isLast={index === account.members.totalCount - 1}
          main={
            <UserAvatarLink
              id={id}
              rowId={rowId}
              name={name || email}
              avatar={avatar}
              noLink={pending}
            />
          }
          cell1={
            <Switch
              label="leader role"
              on={leader}
              disabled={!(isSuperuser || isLeader)}
              handleChange={() => this.switchRole('LEADER', leader, rowId)}
            />
          }
          cell2={
            <Switch
              label="admin role"
              on={admin}
              disabled={!canUpdate}
              handleChange={() => this.switchRole('ADMIN', admin, rowId)}
            />
          }
          cell3={pluralize('Team', teams.totalCount)}
          cell4={
            canUpdate &&
            pending && (
              <PendingInvite
                accountId={account.id}
                accountRowId={account.rowId}
                connectionKey={MEMBER_KEY}
                email={email}
                organizationId={organizationId}
                organizationRowId={organizationRowId}
                userId={id}
                userRowId={rowId}
                expired={inviteExpired}
              />
            )
          }
        />
      </div>
    );
  };

  render() {
    const { organization: { account: { members } } } = this.props;

    const sortedMembers = [...members.edges].sort(usersAlphabeticalCompareFn);

    return (
      <>
        <Directory
          showColumnTitles
          columnTitle="Name"
          columnTitle1="leaders"
          columnTitle2="owners"
          columnTitle3="Teams"
          columnInfo1="Leaders can create, update and delete workflows"
          columnInfo2="Owners can invite users, create teams and add users to teams"
        >
          {sortedMembers.map((edge, index) =>
            this.renderUserRow(edge.node, index)
          )}
        </Directory>
      </>
    );
  }
}

export default createFragmentContainer(
  compose(withGqlClient, withSnackbarsContext, withUser, injectSheet(styles))(
    People
  ),
  {
    organization: graphql`
      fragment people_organization on Organization
        @argumentDefinitions(
          teamFilter: { type: "TeamFilter", defaultValue: {} }
          memberFilter: { type: "MemberFilter", defaultValue: {} }
        ) {
        ...peopleHeader_organization
        id # Field not used within the component but needed by removeOrgMember mutation.
        rowId # Field not used within the component but needed by editAccountMembershipOrg mutation to query the licenses left.
        canUpdate
        isLeader
        account {
          id
          rowId
          # In order to use a search filter, we need to fetch the 'edges'. We also need to pass in an empty array as a second argument of the @connection declaration so relay ignores the filter argument in the store.
          # The first argument represents the number of members we fetch on load
          members(first: null, filter: $memberFilter)
            @connection(key: "People_members", filters: []) {
            totalCount
            edges {
              node {
                admin
                leader
                userId # Field not used within the component but needed by editAccountMembershipOrg mutation.
                collaborator
                user {
                  id
                  rowId
                  name
                  email
                  avatar
                  pending
                  inviteExpired
                  teams(filter: $teamFilter) {
                    totalCount
                  }
                }
              }
            }
          }
        }
      }
    `
  }
);
