import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import { withRouter } from 'react-router-dom';

import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import pathToRegexp from 'path-to-regexp';

import nameToInitials from 'utils/nameToInitials';

import {
  AvatarGroup,
  Directory,
  DirectoryRow,
  Pushtext,
  Pushbutton,
  Avatar,
  Collection,
  CollectionItem
} from '@stratumn/atomic';

import { withGqlClient } from 'wrappers';

import { ROUTE_TEAM, ROUTE_EDIT_BOT_PROFILE } from 'constant/routes';

import RemoveOrgBotModal from './removeOrgBotModal';
import RemoveTeamBotModal from './removeTeamBotModal';
import AddBotKeyModal from './addBotKeyModal';
import ExpireKeyModal from './expireKeyModal';
import Keys from './keys';

import styles from './bot.style';

const INITIAL_STATE = {
  team: null,
  showModalTeam: false,
  showModalOrg: false,
  showModalAddKey: false,
  expiredKey: null,
  showModalExpireKey: false
};

export class Bot extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired,
    history: PropType.object.isRequired,
    bot: PropType.object.isRequired
  };

  state = INITIAL_STATE;
  pkTimeoutRef = React.createRef(null);

  componentWillUnmount = () => {
    if (this.pkTimeoutRef.current) {
      clearTimeout(this.pkTimeoutRef.current);
    }
  };

  toggleModalOrg = () =>
    this.setState({
      showModalOrg: !this.state.showModalOrg
    });

  toggleModalTeam = team =>
    this.setState({
      team,
      showModalTeam: !this.state.showModalTeam
    });

  toggleModalAddKey = () =>
    this.setState({
      showModalAddKey: !this.state.showModalAddKey
    });

  toggleModalExpireKey = expiredKey =>
    this.setState({
      expiredKey,
      showModalExpireKey: !this.state.showModalExpireKey
    });

  renderTeamRow = (node, index) => {
    const { id, rowId, name, avatar, canUpdate } = node;
    const { history, classes, bot: { teams: { totalCount } } } = this.props;

    return (
      <DirectoryRow
        key={id}
        isLast={index === totalCount - 1}
        main={
          <Pushtext
            onClick={() =>
              history.push(pathToRegexp.compile(ROUTE_TEAM)({ id: rowId }))
            }
          >
            <div className={classes.link}>
              <AvatarGroup
                initials={nameToInitials(name)}
                nth={Number(rowId)}
                size={37}
                src={avatar}
              />
              <span className={classes.teamName}>{name}</span>
            </div>
          </Pushtext>
        }
        cell1={
          canUpdate && (
            <Pushtext onClick={() => this.toggleModalTeam(node)}>
              Remove
            </Pushtext>
          )
        }
      />
    );
  };

  render() {
    const { classes, history, bot } = this.props;
    const {
      showModalTeam,
      team,
      showModalOrg,
      showModalAddKey,
      showModalExpireKey,
      expiredKey
    } = this.state;

    return (
      <>
        <div className={classes.userHeaderWrapper}>
          <div>
            <Avatar size={56} src={bot.avatar} />
          </div>
          {bot.organization.canUpdate && (
            <div className={classes.updateActions}>
              <div className={classes.removeAction}>
                <Pushtext
                  mute
                  warning
                  onClick={this.toggleModalOrg}
                  dataCy="remove-from-org"
                >
                  Remove from organization...
                </Pushtext>
              </div>
              <Pushbutton
                secondary
                onClick={() =>
                  history.push(
                    pathToRegexp.compile(ROUTE_EDIT_BOT_PROFILE)({
                      id: bot.rowId
                    })
                  )
                }
                dataCy="edit-bot"
              >
                Edit Bot
              </Pushbutton>
            </div>
          )}
        </div>
        {showModalTeam && (
          <RemoveTeamBotModal
            bot={bot}
            team={team}
            toggleModal={this.toggleModalTeam}
          />
        )}
        {showModalOrg && (
          <RemoveOrgBotModal bot={bot} toggleModal={this.toggleModalOrg} />
        )}
        {showModalAddKey && (
          <AddBotKeyModal bot={bot} toggleModal={this.toggleModalAddKey} />
        )}
        {showModalExpireKey && (
          <ExpireKeyModal
            keyName={expiredKey.name}
            keyObj={expiredKey.key}
            toggleModal={this.toggleModalExpireKey}
          />
        )}
        <Collection>
          <div className={classes.collectionItemFirstChild}>
            <CollectionItem
              label="bot name"
              value={bot.name}
              idle={!bot.name}
            />
          </div>
          {bot.description && (
            <CollectionItem label="description" value={bot.description} />
          )}
          <div className={classes.teamsList}>
            <Directory showColumnTitles columnTitle="teams">
              {bot.teams.nodes.map((index, node) =>
                this.renderTeamRow(index, node)
              )}
            </Directory>
          </div>
          <Keys
            keys={bot.account.keys.nodes}
            expireKey={this.toggleModalExpireKey}
            addKey={this.toggleModalAddKey}
          />
        </Collection>
      </>
    );
  }
}

export default createFragmentContainer(
  compose(withRouter, withGqlClient, injectSheet(styles))(Bot),
  {
    bot: graphql`
      fragment bot_bot on Bot {
        id
        rowId
        name
        description
        avatar
        account {
          keys(orderBy: $orderKeysBy) {
            nodes {
              id
              rowId
              publicKey
              deprecated
              createdAt
              deprecatedAt
            }
          }
        }
        organization {
          id
          rowId
          name
          canUpdate
        }
        teams {
          totalCount
          nodes {
            id
            rowId
            name
            avatar
            canUpdate
          }
        }
      }
    `
  }
);
