import React, { useState } from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';

import { withRouter } from 'react-router-dom';
import { withGqlClient, withUser, withSpecificError } from 'wrappers';
import { ROUTE_TEAMS } from 'constant/routes';

import { SearchBar } from 'components/ui';
import { SEARCH_DELAY } from 'constant/timer';
import { useDebounce, useError } from 'utils/hooks';

import { LoaderTraceLogo } from '@stratumn/atomic';
import Team from './team';
import TeamHeader from './teamHeader';

const query = graphql`
  query teamRootQuery(
    $teamRowId: BigInt!
    $memberFilter: MemberFilter
    $botFilter: BotFilter
  ) {
    teamByRowId(rowId: $teamRowId) {
      ...teamHeader_team
      ...team_team
        @arguments(memberFilter: $memberFilter, botFilter: $botFilter)
    }
  }
`;

export const Renderer = withSpecificError(
  ({
    error,
    props: data,
    errorContext: { handleError },
    match: { params }
  }) => {
    if (!data && !error) return <LoaderTraceLogo />;

    let teamRowId;

    if (data) teamRowId = data.teamByRowId;

    const specificError = {
      description: `the team with the id ${params.id} does not exist.`,
      label: "take me to the teams' page",
      path: ROUTE_TEAMS
    };

    useError(teamRowId, handleError, specificError);

    if (error) return null;
    if (!teamRowId) return null;

    return (
      <>
        <TeamHeader team={teamRowId} />
        <Team team={teamRowId} />
      </>
    );
  }
);

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const TeamRoot = props => {
  const { environment, selectedOrganization, teamRowId } = props;
  const variables = {};

  const [char, setChar] = useState('');
  const search = useDebounce(char, SEARCH_DELAY);

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }

  variables.teamRowId = teamRowId;

  // apply the same filter to members and bots
  variables.memberFilter = {
    or: [
      { name: { likeInsensitive: `%${search}%` } },
      { email: { likeInsensitive: `%${search}%` } }
    ]
  };
  variables.botFilter = { name: { likeInsensitive: `%${search}%` } };

  // We need to make sure that the location props are passed to child components correctly.
  // https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/react-router/docs/guides/blocked-updates.md
  const RendererWithRouter = withRouter(Renderer);

  return (
    <>
      <SearchBar
        label="Search Members & Bots"
        search={char}
        setSearch={setChar}
      >
        <div id="btn-invite-collaborators" />
      </SearchBar>

      <QueryRenderer
        environment={environment}
        query={query}
        variables={variables}
        render={RendererWithRouter}
      />
    </>
  );
};

TeamRoot.propTypes = {
  environment: PropType.object.isRequired,
  teamRowId: PropType.string.isRequired,
  selectedOrganization: PropType.object
};

TeamRoot.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(TeamRoot);
