import React from 'react';
import PropType from 'prop-types';
import { withSnackbarsContext } from '@stratumn/atomic';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';

import { ROOT_API_URL } from 'constant/api';
import Client from './client';

const RestClientContext = React.createContext(() => {});

export const withRestClient = Component => props => (
  <RestClientContext.Consumer>
    {({ client }) => <Component client={client} {...props} />}
  </RestClientContext.Consumer>
);

class RestClient extends React.PureComponent {
  static propTypes = {
    history: PropType.object.isRequired,
    errorSnackbar: PropType.func.isRequired,
    children: PropType.node.isRequired
  };

  constructor(props) {
    super(props);
    const { errorSnackbar, history } = props;
    this.client = new Client(ROOT_API_URL, errorSnackbar, history);
  }

  render() {
    return (
      <RestClientContext.Provider value={{ client: this.client }}>
        {this.props.children}
      </RestClientContext.Provider>
    );
  }
}

export default compose(withRouter, withSnackbarsContext)(RestClient);
