import to from 'await-to-js';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import Fetcher, { FetchError } from 'utils/fetcher';

const createEnvironment = (rootUrl, displayFn, history) => {
  const fetcher = new Fetcher(displayFn, history);
  const fetchQuery = async (operation, variables) => {
    const [err, rsp] = await to(
      fetcher.post(`${rootUrl}/graphql`, {
        query: operation.text,
        variables
      })
    );
    if (err) {
      if (err instanceof FetchError) return null;
      throw err;
    }
    return rsp;
  };

  return new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource())
  });
};

export default createEnvironment;
