import React, { useState } from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { withGqlClient, withUser } from 'wrappers';

import { SearchBar } from 'components/ui';
import { SEARCH_DELAY } from 'constant/timer';
import { useDebounce } from 'utils/hooks';

import Collaborators from './collaborators';
import CollaboratorsHeader from './collaboratorsHeader';

const query = graphql`
  query collaboratorsRootQuery(
    $orgRowId: BigInt!
    $teamFilter: TeamFilter
    $collaboratorFilter: UserFilter
  ) {
    organizationByRowId(rowId: $orgRowId) {
      ...collaboratorsHeader_organization
      ...collaborators_organization
        @arguments(
          teamFilter: $teamFilter
          collaboratorFilter: $collaboratorFilter
        )
    }
  }
`;

export const Renderer = ({ error, props: data }) => {
  if (error) return <div>error!</div>;
  if (!data) return null;
  const { organizationByRowId } = data;
  return (
    <>
      <CollaboratorsHeader organization={organizationByRowId} />
      <Collaborators organization={organizationByRowId} />
    </>
  );
};

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const CollaboratorsRoot = props => {
  const { environment, selectedOrganization } = props;
  const variables = {};

  const [char, setChar] = useState('');
  const search = useDebounce(char, SEARCH_DELAY);

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }
  const { rowId } = selectedOrganization;
  variables.orgRowId = rowId;
  /* 
   Important teamfilter:
   We only want the count of the teams that the collaborator is a part of in this organization. 
   Otherwise, if a collaborator is a part of teams in multiple organizations we will get the wrong count.
  */
  variables.teamFilter = { organizationId: { equalTo: rowId } };
  variables.collaboratorFilter = {
    or: [
      { name: { likeInsensitive: `%${search}%` } },
      { email: { likeInsensitive: `%${search}%` } }
    ]
  };

  const RendererWithRouter = withRouter(Renderer);

  return (
    <>
      <SearchBar label="Search Collaborators" search={char} setSearch={setChar}>
        <div id="btn-download-csv" />
      </SearchBar>

      <QueryRenderer
        environment={environment}
        query={query}
        variables={variables}
        render={RendererWithRouter}
      />
    </>
  );
};

CollaboratorsRoot.propTypes = {
  environment: PropType.object.isRequired,
  selectedOrganization: PropType.object
};

CollaboratorsRoot.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(CollaboratorsRoot);
