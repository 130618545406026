import React, { useState } from 'react';
import PropType from 'prop-types';
import { withRouter } from 'react-router-dom';

import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';

import { useDebounce } from 'utils/hooks';

import { SearchBar } from 'components/ui';

import { withGqlClient, withUser } from 'wrappers';
import { SEARCH_DELAY } from 'constant/timer';

import Bots from './bots';
import BotsHeader from './botsHeader';

const query = graphql`
  query botsRootQuery($orgRowId: BigInt!, $botFilter: BotFilter) {
    organizationByRowId(rowId: $orgRowId) {
      ...botsHeader_organization
      ...bots_organization @arguments(botFilter: $botFilter)
    }
  }
`;

export const Renderer = ({ error, props: data }) => {
  if (error) return <div>error!</div>;
  if (!data) return null;
  const { organizationByRowId } = data;
  return (
    <>
      <BotsHeader organization={organizationByRowId} />
      <Bots organization={organizationByRowId} />
    </>
  );
};

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const BotsRoot = props => {
  const { environment, selectedOrganization } = props;
  const variables = {};

  const [char, setChar] = useState('');
  const search = useDebounce(char, SEARCH_DELAY);

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }

  const { rowId } = selectedOrganization;
  variables.orgRowId = rowId;
  variables.botFilter = {
    name: { likeInsensitive: `%${search}%` }
  };
  // variables.orderBy = TEAMS_ORDER_BY;

  // We need to make sure that the location props are passed to child components correctly.
  // https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/react-router/docs/guides/blocked-updates.md
  const RendererWithRouter = withRouter(Renderer);

  return (
    <>
      <SearchBar label="Search Bots" search={char} setSearch={setChar}>
        <div id="btn-create-new-bot" />
      </SearchBar>

      <QueryRenderer
        environment={environment}
        query={query}
        variables={variables}
        render={RendererWithRouter}
      />
    </>
  );
};

BotsRoot.propTypes = {
  environment: PropType.object.isRequired,
  selectedOrganization: PropType.object
};

BotsRoot.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(BotsRoot);
