/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type editBotProfile_bot$ref = any;
export type editBotProfileQueryVariables = {|
  botRowId: any
|};
export type editBotProfileQueryResponse = {|
  +botByRowId: ?{|
    +$fragmentRefs: editBotProfile_bot$ref
  |}
|};
export type editBotProfileQuery = {|
  variables: editBotProfileQueryVariables,
  response: editBotProfileQueryResponse,
|};
*/


/*
query editBotProfileQuery(
  $botRowId: BigInt!
) {
  botByRowId(rowId: $botRowId) {
    ...editBotProfile_bot
    id
  }
}

fragment editBotProfile_bot on Bot {
  id
  rowId
  name
  description
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "botRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "botRowId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editBotProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Bot",
        "kind": "LinkedField",
        "name": "botByRowId",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "editBotProfile_bot"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editBotProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Bot",
        "kind": "LinkedField",
        "name": "botByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "editBotProfileQuery",
    "operationKind": "query",
    "text": "query editBotProfileQuery(\n  $botRowId: BigInt!\n) {\n  botByRowId(rowId: $botRowId) {\n    ...editBotProfile_bot\n    id\n  }\n}\n\nfragment editBotProfile_bot on Bot {\n  id\n  rowId\n  name\n  description\n  avatar\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '18a50dcc3106965f07e150d239940393';

module.exports = node;
