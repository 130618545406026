import React, { useEffect, useRef, useState } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import pathToRegexp from 'path-to-regexp';
import { withRouter } from 'react-router-dom';

import { Directory, Pushbutton } from '@stratumn/atomic';

import { createPaginationContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';

import { withGqlClient } from 'wrappers';
import { ROUTE_TEAM } from 'constant/routes';
import { TEAMS_PER_PAGE } from 'constant/types';

import styles from './teams.style';
import TeamRow from './teamRow';

const renderTeamRow = (history, teams, node, index) => (
  <TeamRow
    key={node.id}
    index={index}
    team={node}
    teamsTotalCount={teams.totalCount}
    onClick={rowId =>
      history.push(pathToRegexp.compile(ROUTE_TEAM)({ id: rowId }))
    }
  />
);

export const Teams = ({ classes, history, relay, organization: { teams } }) => {
  const TEAM_ROW_HEIGHT_IN_PX = 53;
  const NUMBER_OF_TEAMS_LEFT_BEFORE_LOAD = Math.ceil(TEAMS_PER_PAGE / 3);
  const BOTTOM_THRESHOLD_IN_PX =
    NUMBER_OF_TEAMS_LEFT_BEFORE_LOAD * TEAM_ROW_HEIGHT_IN_PX;

  const directoryRef = useRef(null);
  const [canTableScroll, setCanTableScroll] = useState(false);

  const handleScroll = () => {
    if (isCloseToBottomOfTable()) loadMore();
  };

  const handleLoadMoreButtonDisplay = () => {
    setCanTableScroll(isTableScrollable);
  };

  const isTableScrollable = () =>
    directoryRef.current.scrollHeight > directoryRef.current.clientHeight;

  const isCloseToBottomOfTable = () => {
    const tableHeight =
      directoryRef.current.scrollHeight - directoryRef.current.clientHeight;
    return (
      directoryRef.current.scrollTop >= tableHeight - BOTTOM_THRESHOLD_IN_PX
    );
  };

  useEffect(
    () => {
      if (!directoryRef.current) return null;
      addEventListeners();
      handleLoadMoreButtonDisplay();
      return () => removeEventListeners();
    },
    [directoryRef]
  );

  const loadMore = () => {
    if (!relay.hasMore()) {
      removeEventListeners();
      return;
    }
    if (relay.isLoading()) return;

    relay.loadMore(TEAMS_PER_PAGE, error => {
      console.log('error: ', error);
    });
  };

  const addEventListeners = () => {
    directoryRef.current.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleLoadMoreButtonDisplay);
  };

  const removeEventListeners = () => {
    window.removeEventListener('resize', handleLoadMoreButtonDisplay);
    if (!directoryRef.current) return;
    directoryRef.current.removeEventListener('scroll', handleScroll);
  };

  return (
    <Directory
      showColumnTitles
      columnTitle="Name"
      columnTitle2="Bots"
      columnTitle1="Members"
      ref={directoryRef}
    >
      {teams.edges.map((edge, index) =>
        renderTeamRow(history, teams, edge.node, index)
      )}
      {teams.pageInfo.hasNextPage &&
        !canTableScroll && (
          <div className={classes.pushbuttonWrapper}>
            <Pushbutton secondary onClick={loadMore}>
              Show More Teams
            </Pushbutton>
          </div>
        )}
    </Directory>
  );
};

Teams.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  organization: PropType.object.isRequired,
  relay: PropType.object.isRequired
};

/**
 * type ConnectionConfig
 */
const ConnectionConfig = {
  direction: 'forward',
  // These are the variables used to read the data from the fragment.
  getFragmentVariables(prevVars, totalCount) {
    return {
      ...prevVars,
      count: totalCount
    };
  },
  // These are the variables to use when sending the pagination query.
  getVariables(props, { count, cursor }, fragmentVariables) {
    return {
      cursor,
      count,
      orderBy: fragmentVariables.orderBy,
      orgRowId: fragmentVariables.orgRowId
    };
  },
  query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query teamsQuery(
      $count: Int!
      $cursor: Cursor
      $orderBy: [TeamsOrderBy!]!
      $orgRowId: BigInt!
      $teamFilter: TeamFilter
    ) {
      organizationByRowId(rowId: $orgRowId) {
        teams(
          first: $count
          after: $cursor
          orderBy: $orderBy
          filter: $teamFilter
        ) @connection(key: "Teams_teams", filters: []) {
          edges {
            node {
              ...teamRow_team
            }
          }
        }
      }
    }
  `
};

export default createPaginationContainer(
  compose(withRouter, withGqlClient, injectSheet(styles))(Teams), // component
  {
    organization: graphql`
      fragment teams_organization on Organization
        @argumentDefinitions(
          count: { type: "Int" }
          cursor: { type: "Cursor" }
          orderBy: { type: "[TeamsOrderBy!]" }
          teamFilter: { type: "TeamFilter" }
        ) {
        ...teamsHeader_organization
        account {
          canUpdate
        }
        teams(
          first: $count
          after: $cursor
          orderBy: $orderBy
          filter: $teamFilter
        ) @connection(key: "Teams_teams", filters: []) {
          totalCount
          edges {
            cursor
            node {
              id
              ...teamRow_team
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `
  },
  ConnectionConfig
);
