import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import pathToRegexp from 'path-to-regexp';
import { withRouter } from 'react-router-dom';

import { Directory } from '@stratumn/atomic';

import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';

import { withGqlClient } from 'wrappers';
import { ROUTE_BOT } from 'constant/routes';

import styles from './bots.style';
import BotRow from './botRow';

/**
 * Compare function to use with array.sort to sort bots edges
 * @param {object} edgeA The first edge bot element for comparison.
 * @param {object} edgeB The second edge bot element for comparison.
 * @returns -1, 1 or 0 depending on the bots name alphabetical order.
 */
const botsAlphabeticalCompareFn = (edgeA, edgeB) => {
  if (edgeA.node.name.toLowerCase() < edgeB.node.name.toLowerCase()) {
    return -1;
  }
  if (edgeA.node.name.toLowerCase() > edgeB.node.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

const renderBotRow = (history, bots, node, index) => (
  <BotRow
    key={node.id}
    index={index}
    bot={node}
    botsTotalCount={bots.totalCount}
    onClick={rowId =>
      history.push(pathToRegexp.compile(ROUTE_BOT)({ id: rowId }))
    }
  />
);

export function Bots({ history, organization: { bots } }) {
  const sortedBots = [...bots.edges].sort(botsAlphabeticalCompareFn);

  return (
    <Directory showColumnTitles columnTitle="Name" columnTitle1="Teams">
      {sortedBots.map((edge, index) =>
        renderBotRow(history, bots, edge.node, index)
      )}
    </Directory>
  );
}

Bots.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  organization: PropType.object.isRequired
};

/**
 * type ConnectionConfig
 */
const ConnectionConfig = {
  direction: 'forward',
  // These are the variables used to read the data from the fragment.
  getFragmentVariables(prevVars, totalCount) {
    return {
      ...prevVars,
      count: totalCount
    };
  },
  // These are the variables to use when sending the pagination query.
  getVariables(props, { count, cursor }, fragmentVariables) {
    return {
      cursor,
      count,
      orgRowId: fragmentVariables.orgRowId
    };
  },
  query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query botsQuery(
      $cursor: Cursor
      $orgRowId: BigInt!
      $botFilter: BotFilter
    ) {
      organizationByRowId(rowId: $orgRowId) {
        bots(first: null, after: $cursor, filter: $botFilter)
          @connection(key: "Bots_bots", filters: []) {
          edges {
            node {
              id
              rowId
              name
              avatar
              description
              teams {
                totalCount
              }
            }
          }
        }
      }
    }
  `
};

export default createFragmentContainer(
  compose(withRouter, withGqlClient, injectSheet(styles))(Bots), // component
  {
    organization: graphql`
      fragment bots_organization on Organization
        @argumentDefinitions(
          count: { type: "Int" }
          cursor: { type: "Cursor" }
          botFilter: { type: "BotFilter" }
        ) {
        ...botsHeader_organization
        account {
          canUpdate
        }
        bots(first: $count, after: $cursor, filter: $botFilter)
          @connection(key: "Bots_bots", filters: []) {
          totalCount
          edges {
            cursor
            node {
              id
              rowId
              name
              avatar
              description
              teams {
                totalCount
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `
  },
  ConnectionConfig
);
