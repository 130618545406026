import React from 'react';
import PropType from 'prop-types';
import to from 'await-to-js';
import { withSnackbarsContext } from '@stratumn/atomic';

import { Button } from 'components/ui';
import { StepperContext } from 'components/ui/stepper';

export const Submit = ({
  isValid,
  onSubmit,
  errorSnackbar,
  successSnackbar,
  ...props
}) => (
  <StepperContext.Consumer>
    {({ finalStep }) => {
      const onClick = async () => {
        const [err, result] = await to(onSubmit());
        if (err) errorSnackbar(err.message);
        else finalStep(result);
      };

      return (
        <Button onClick={onClick} disabled={!isValid} {...props}>
          Submit
        </Button>
      );
    }}
  </StepperContext.Consumer>
);

Submit.propTypes = {
  isValid: PropType.bool.isRequired,
  onSubmit: PropType.func.isRequired,
  errorSnackbar: PropType.func.isRequired
};

export default withSnackbarsContext(Submit);
