import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { useError } from 'utils/hooks';

import { withGqlClient, withUser, withSpecificError } from 'wrappers';
import { ROUTE_BOTS } from 'constant/routes';

import { LoaderTraceLogo } from '@stratumn/atomic';
import EditBotProfile from './editBotProfile';

const query = graphql`
  query editBotProfileQuery($botRowId: BigInt!) {
    botByRowId(rowId: $botRowId) {
      ...editBotProfile_bot
    }
  }
`;

export const Renderer = withSpecificError(
  ({
    error,
    props: data,
    errorContext: { handleError },
    match: { params }
  }) => {
    if (!data && !error) return <LoaderTraceLogo />;

    let bot;

    if (data) bot = data.botByRowId;

    const specificError = {
      description: `the bot with the id ${params.id} does not exist.`,
      label: "take me to the bots' page",
      path: ROUTE_BOTS
    };

    useError(params.id, handleError, specificError);

    if (error) return null;
    if (!bot) return null;

    return <EditBotProfile bot={bot} />;
  }
);

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const EditBotProfileRoot = props => {
  const { environment, botRowId } = props;

  const variables = {};
  variables.botRowId = botRowId;

  // We need to make sure that the location props are passed to child components correctly.
  // https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/react-router/docs/guides/blocked-updates.md
  const RendererWithRouter = withRouter(Renderer);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={RendererWithRouter}
    />
  );
};

EditBotProfileRoot.propTypes = {
  environment: PropType.object.isRequired,
  botRowId: PropType.string.isRequired
};

EditBotProfileRoot.defaultProps = {};

export default compose(withUser, withGqlClient)(EditBotProfileRoot);
