import bcrypt from 'bcryptjs';
import MediaClient from '@stratumn/media-sdk';

import { SpanType, withSpanAsync } from 'tracing';
import Fetcher from 'utils/fetcher';
import { MEDIA_API_URL } from 'constant/api';

import {
  ROUTE_INVITE_SIGNUP,
  ROUTE_INVITE_PASSWORDRESET,
  ROUTE_INVITE_SIGNUP_PENDING,
  ROUTE_REVOKE_SIGNUP,
  ROUTE_SIGNUP,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_PASSWORD_RESET,
  ROUTE_SALT,
  ROUTE_GOOGLE_AUTH,
  ROUTE_PASSWORD_UPDATE,
  ROUTE_CHECK_INVITE
} from 'constant/routes';

const mediaClient = new MediaClient(MEDIA_API_URL);

export default class Client {
  constructor(rootUrl, displayFn, history) {
    const fetcher = new Fetcher(displayFn, history);
    this._get = (path, data) => fetcher.get(`${rootUrl}${path}`, data);
    this._post = (path, data) => fetcher.post(`${rootUrl}${path}`, data);
  }

  media = mediaClient;

  _getSalt = async email => {
    const { salt } = await this._get(ROUTE_SALT, { email });
    return salt;
  };

  login = async (email, password, oauthPayload, stayLoggedIn) =>
    withSpanAsync('login', SpanType.outgoingRequest, async () => {
      const salt = await this._getSalt(email);
      const passwordHash = bcrypt.hashSync(password, salt);
      const { token, authCode, redirectUri } = await this._post(ROUTE_LOGIN, {
        email,
        passwordHash,
        oauthPayload,
        stayLoggedIn
      });

      return { token, authCode, redirectUri };
    });

  logout = async () =>
    withSpanAsync('logout', SpanType.outgoingRequest, async () =>
      this._get(ROUTE_LOGOUT)
    );

  signup = async (firstName, lastName, password, invitationToken) =>
    withSpanAsync('signup', SpanType.outgoingRequest, async () => {
      const salt = bcrypt.genSaltSync(10);
      const passwordHash = bcrypt.hashSync(password, salt);

      const { token } = await this._post(ROUTE_SIGNUP, {
        firstName,
        lastName,
        passwordHash,
        salt,
        invitationToken
      });

      return token;
    });

  oAuth = async (state, code, oauthPayload) =>
    withSpanAsync('oauth', SpanType.outgoingRequest, async () => {
      const { token, authCode, redirectUri } = await this._post(
        ROUTE_GOOGLE_AUTH,
        {
          state,
          code,
          oauthPayload
        }
      );

      return { token, authCode, redirectUri };
    });

  invite = email =>
    withSpanAsync('invite', SpanType.outgoingRequest, () =>
      this._post(ROUTE_INVITE_SIGNUP, { email })
    );

  pendingInvitations = () =>
    withSpanAsync('pendingInvitations', SpanType.outgoingRequest, () =>
      this._get(ROUTE_INVITE_SIGNUP_PENDING)
    );

  revokeInvitation = token =>
    withSpanAsync('revokeInvitation', SpanType.outgoingRequest, () =>
      this._post(ROUTE_REVOKE_SIGNUP, { token })
    );

  forgot = email =>
    withSpanAsync('forgot', SpanType.outgoingRequest, () =>
      this._post(ROUTE_INVITE_PASSWORDRESET, { email })
    );

  reset = (password, invitationToken) =>
    withSpanAsync('reset', SpanType.outgoingRequest, async () => {
      const salt = bcrypt.genSaltSync(10);
      const passwordHash = bcrypt.hashSync(password, salt);
      const { token } = await this._post(ROUTE_PASSWORD_RESET, {
        salt,
        passwordHash,
        invitationToken
      });
      return token;
    });

  updatePassword = (email, oldPassword, newPassword) =>
    withSpanAsync('updatePassword', SpanType.outgoingRequest, async () => {
      const salt = await this._getSalt(email);
      const oldPasswordHash = bcrypt.hashSync(oldPassword, salt);
      const newPasswordHash = bcrypt.hashSync(newPassword, salt);
      return this._post(ROUTE_PASSWORD_UPDATE, {
        oldPasswordHash,
        newPasswordHash
      });
    });

  checkInvite = token =>
    withSpanAsync('checkInvite', SpanType.outgoingRequest, () =>
      this._post(ROUTE_CHECK_INVITE, { token })
    );
}
