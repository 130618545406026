import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import * as Sentry from '@sentry/react';
import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { addTeamBots } from 'mutations';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  Check
} from '@stratumn/atomic';

import { TableRow, UserAvatarLink } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient } from 'wrappers';

import pluralize from 'utils/pluralize';

import styles from './addTeamBotsModal.style';

export class AddTeamBotsModal extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    environment: PropType.object.isRequired,
    team: PropType.object.isRequired,
    onClose: PropType.func.isRequired
  };

  state = {
    selected: {},
    submitting: false
  };

  getSelectedArr = () => Object.keys(this.state.selected);

  toggleSelected = rowId => () => {
    const selected = { ...this.state.selected };

    if (selected[rowId]) {
      delete selected[rowId];
    } else {
      selected[rowId] = true;
    }

    this.setState({ selected });
  };

  onSubmit = () =>
    withSpanAsync('addTeamBots', SpanType.processing, async () => {
      const { environment, team, onClose } = this.props;

      this.setState({ submitting: true });

      const [err] = await to(
        addTeamBots(environment, team.id, team.rowId, this.getSelectedArr())
      );

      if (err) {
        Sentry.captureException(err);
        this.setState({ submitting: false });
      } else {
        onClose();
      }

      return {};
    });

  renderNode = ({ rowId, name, avatar }) => {
    const { selected } = this.state;

    return (
      <TableRow key={rowId}>
        <UserAvatarLink rowId={rowId} name={name} avatar={avatar} noLink />
        <Check
          label={`add ${name} to the Team`}
          handleChange={this.toggleSelected(rowId)}
          checked={selected[rowId]}
        />
      </TableRow>
    );
  };

  renderSelector = botsOutsideTeam => {
    const { classes, team } = this.props;

    return (
      <>
        <div className={classes.title}>
          Select the bots to add to the{' '}
          <span className={classes.teamName}>{team.name}</span> Team.
        </div>
        <ul className={classes.botsList}>
          {botsOutsideTeam.map(this.renderNode)}
        </ul>
      </>
    );
  };

  renderAllInOrg = () => {
    const { classes, team } = this.props;

    return (
      <div className={classes.title}>
        All the bots in this organization are already a part of the{' '}
        <span className={classes.teamName}>{team.name}</span> Team.
      </div>
    );
  };

  render() {
    const { onClose, team } = this.props;
    const { organization: { bots } } = team;
    const { submitting } = this.state;

    const numBotsCount = this.getSelectedArr().length;

    let submitLabel;
    switch (numBotsCount) {
      case 0:
        submitLabel = 'Add Team Bots';
        break;
      default:
        submitLabel = `Add ${pluralize('Team Bot', numBotsCount)}`;
    }

    const botsOutsideTeam = bots.nodes.filter(({ teams }) => {
      for (let idx = 0; idx < teams.nodes.length; idx += 1) {
        const { rowId: teamRowId } = teams.nodes[idx];
        if (teamRowId === team.rowId) {
          return false;
        }
      }
      return true;
    });

    return (
      <Modal
        title="Add Bots"
        closeButtonLabel="Cancel"
        handleCollapse={onClose}
      >
        <ModalContent>
          {botsOutsideTeam.length
            ? this.renderSelector(botsOutsideTeam)
            : this.renderAllInOrg()}
        </ModalContent>
        <ModalActions>
          <Pushbutton
            primary
            onClick={this.onSubmit}
            disabled={submitting || !numBotsCount}
          >
            {submitLabel}
          </Pushbutton>
        </ModalActions>
      </Modal>
    );
  }
}

export default createFragmentContainer(
  compose(withGqlClient, injectSheet(styles))(AddTeamBotsModal),
  {
    team: graphql`
      fragment addTeamBotsModal_team on Team
        @argumentDefinitions(teamAccountRowId: { type: "BigInt!" }) {
        organization {
          bots {
            nodes {
              rowId
              name
              avatar
              teams {
                nodes {
                  rowId
                }
              }
            }
          }
        }
        account {
          id
        }
        rowId
        id
        name
      }
    `
  }
);
