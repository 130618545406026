import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import {
  ROUTE_ORG,
  ROUTE_MEMBERS,
  ROUTE_MEMBER,
  ROUTE_TEAMS,
  ROUTE_TEAM,
  ROUTE_COLLABORATORS,
  ROUTE_COLLABORATOR,
  ROUTE_BOTS,
  ROUTE_BOT,
  ROUTE_MY_PROFILE
} from 'constant/routes';
import compose from 'lodash.flowright';
import { Oops } from '@stratumn/atomic';
import { withGqlClient, withUser } from 'wrappers';
import Organization from './organization';
import People from './people';
import Bots from './bots';
import Member from './member';
import Bot from './bot';
import Teams from './teams';
import Team from './team';
import Collaborators from './collaborators';
import Collaborator from './collaborator';
import NotFound from './notFound';

import styles from './organization/organization.style';

export const OrganizationLayout = ({
  classes,
  history,
  selectedOrganization
}) => {
  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  const isUserInOrganization = !!(
    selectedOrganization && selectedOrganization.id
  );

  const renderNoOrganization = () => (
    <Oops
      label="Go back to your profil"
      description="You are not part of any organization"
      onHomeClick={() => {
        history.push(ROUTE_MY_PROFILE);
      }}
    />
  );

  const renderRoutes = () => (
    <Switch>
      <Route exact path={ROUTE_MEMBERS} component={People} />
      <Route exact path={ROUTE_BOTS} component={Bots} />
      <Route exact path={ROUTE_TEAMS} component={Teams} />
      <Route exact path={ROUTE_COLLABORATORS} component={Collaborators} />
      <Route
        exact
        path={ROUTE_TEAM}
        component={({ match: { params: { id } } }) => <Team teamRowId={id} />}
      />
      <Route
        exact
        path={ROUTE_MEMBER}
        component={({ match: { params: { id } } }) => (
          <Member memberRowId={id} />
        )}
      />
      <Route
        exact
        path={ROUTE_BOT}
        component={({ match: { params: { id } } }) => <Bot botRowId={id} />}
      />
      <Route
        exact
        path={ROUTE_COLLABORATOR}
        component={({ match: { params: { id } } }) => (
          <Collaborator collaboratorRowId={id} />
        )}
      />

      {/* To avoid a 404 page when the url matches /organization, we are redirecting the page to the list of members. When we create a page for the organization, we'll remove this redirection. */}
      <Redirect from={ROUTE_ORG} to={ROUTE_MEMBERS} />

      <Route path="*" component={NotFound} />
    </Switch>
  );

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        {isUserInOrganization && <Organization />}
      </div>
      <div className={classes.right}>
        {isUserInOrganization ? renderRoutes() : renderNoOrganization()}
      </div>
    </div>
  );
};

OrganizationLayout.propTypes = {
  classes: PropType.object.isRequired,
  selectedOrganization: PropType.object
};

OrganizationLayout.defaultProps = {
  selectedOrganization: null
};

export default compose(
  withRouter,
  withGqlClient,
  withUser,
  injectSheet(styles)
)(OrganizationLayout);
