export const ROUTE_INVITE_SIGNUP = '/invite/signup';
export const ROUTE_INVITE_PASSWORDRESET = '/invite/passwordreset';
export const ROUTE_INVITE_SIGNUP_PENDING = '/invite/signup/pending';
export const ROUTE_REVOKE_SIGNUP = '/invite/signup/pending';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_LOGIN = '/login';
export const ROUTE_PASSWORD_RESET = '/passwordreset';
export const ROUTE_PASSWORD_UPDATE = '/passwordupdate';
export const ROUTE_PASSWORD_UPDATE_COMPONENT = '/passwordupdate';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_OAUTH = '/oauth';
export const ROUTE_GOOGLE_AUTH = '/googleAuth';
export const ROUTE_SALT = '/salt';
export const ROUTE_MY_PROFILE = '/';
export const ROUTE_EDIT_PROFILE = '/edit-profile';
export const ROUTE_ORG = '/organization';
export const ROUTE_TEAMS = '/organization/teams';
export const ROUTE_TEAM = '/organization/teams/:id';
export const ROUTE_MEMBERS = '/organization/members';
export const ROUTE_MEMBER = '/organization/members/:id';
export const ROUTE_COLLABORATORS = '/organization/collaborators';
export const ROUTE_COLLABORATOR = '/organization/collaborators/:id';
export const ROUTE_BOTS = '/organization/bots';
export const ROUTE_BOT = '/organization/bots/:id';
export const ROUTE_EDIT_BOT_PROFILE = '/edit-bot-profile/:id';
export const ROUTE_UNAUTHORIZED = '/unauthorized';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_FORGOT = '/forgot';
export const ROUTE_RESET = '/passwordreset';
export const ROUTE_GENERATE_KEYS = '/generatekeys';
export const ROUTE_EDIT_PROFILE_SIGNUP = '/editprofilesignup';
export const ROUTE_CHECK_INVITE = '/checkinvite';
export const ROUTE_GDPR_POLICY_PAGE = '/gdpr-privacy-policy';
export const ROUTE_HOME = '/';
