/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type editBotProfile_bot$ref: FragmentReference;
declare export opaque type editBotProfile_bot$fragmentType: editBotProfile_bot$ref;
export type editBotProfile_bot = {|
  +id: string,
  +rowId: any,
  +name: string,
  +description: ?string,
  +avatar: ?string,
  +$refType: editBotProfile_bot$ref,
|};
export type editBotProfile_bot$data = editBotProfile_bot;
export type editBotProfile_bot$key = {
  +$data?: editBotProfile_bot$data,
  +$fragmentRefs: editBotProfile_bot$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editBotProfile_bot",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    }
  ],
  "type": "Bot"
};
// prettier-ignore
(node/*: any*/).hash = '18b310ec4622406462495de977b2d9d7';

module.exports = node;
