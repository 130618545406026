import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import { withRouter } from 'react-router-dom';
import { TEAM_MEMBER_KEY, ADMIN_GRANT, ADMIN_REVOKE } from 'constant/types';
import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import pathToRegexp from 'path-to-regexp';

import { editAccountMembershipTeam, updateTeam } from 'mutations';
import nameToInitials from 'utils/nameToInitials';

import {
  Avatar,
  AvatarGroup,
  Directory,
  DirectoryRow,
  Modal,
  ModalActions,
  ModalContent,
  Prose,
  Pushbutton,
  Pushtext,
  Switch,
  withSnackbarsContext
} from '@stratumn/atomic';

import {
  EditableText,
  Tag,
  UploadPhoto,
  UserAvatarLink,
  PendingInvite
} from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient, withRestClient, withUser } from 'wrappers';
import * as Sentry from '@sentry/react';
import { ROUTE_BOT } from 'constant/routes';

import pluralize from 'utils/pluralize';

import MailingList from './mailingList';

import styles from './team.style';

const WARNING_MESSAGE = 'you have already assigned all your team admin roles';

export class Team extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired,
    client: PropType.object.isRequired,
    environment: PropType.object.isRequired,
    errorSnackbar: PropType.func.isRequired,
    team: PropType.object.isRequired,
    user: PropType.object.isRequired,
    history: PropType.object.isRequired
  };

  state = {
    name: this.props.team.name || '',
    email: this.props.team.email || '',

    submitDisabled: false,
    showModal: false
  };

  deleteTeam = () => console.log('delete team');

  switchRole = (teamAdmin, userId) => {
    const {
      environment,
      team: {
        account,
        rowId: teamRowId,
        organization: { organizationMemberLicensesLeft, canUpdate: orgAdmin }
      },
      user: { rowId },
      errorSnackbar
    } = this.props;

    const { showModal } = this.state;

    /**
     * If the user is neither an organization admin nor a superuser:
     * - display a confirmation modal
     */
    if (userId === rowId && teamAdmin && !orgAdmin && !showModal) {
      return this.toggleModal(userId);
    }

    let action = teamAdmin ? ADMIN_REVOKE : ADMIN_GRANT;

    if (
      organizationMemberLicensesLeft !== null &&
      organizationMemberLicensesLeft <= 0 &&
      !teamAdmin
    ) {
      action = ADMIN_REVOKE;
      return errorSnackbar(WARNING_MESSAGE);
    }

    withSpanAsync(`${action}Admin`, SpanType.processing, async () => {
      this.setState({
        submitDisabled: true
      });
      await editAccountMembershipTeam(
        environment,
        teamRowId,
        'ADMIN',
        userId,
        account.id,
        account.rowId,
        action,
        'Team_members' // Fragment connection key in order to update the relay store
      );

      if (userId === rowId) window.location.reload();
    });

    this.setState({
      submitDisabled: false
    });

    return {};
  };

  editTeam = (avatar, name, email) =>
    withSpanAsync('updateTeam', SpanType.processing, async () => {
      const { client, environment, team, successSnackbar } = this.props;

      let avatarUrl;

      if (avatar instanceof File) {
        const { digest } = await client.media.uploadFile(avatar, {
          disableEncryption: true
        });
        avatarUrl = await client.media.buildDownloadURL(digest);
      }
      let emailValue = email;
      if (email === '') {
        emailValue = null;
      }

      const [err] = await to(
        updateTeam(environment, team.rowId, name, avatarUrl, emailValue)
      );

      if (err) {
        Sentry.captureException(err);
        // Automatically triggers an errorSnackbar
      } else if (avatarUrl) {
        successSnackbar(`Saved new Team's avatar`);
      } else if (email === null) {
        successSnackbar(`Successfully removed the Team's mailing list email`);
        this.setState({ email: '' });
      } else if (email) {
        successSnackbar(`Saved new Team's mailing list email ${email}`);
        this.setState({ email });
      } else {
        successSnackbar(`Saved new Team's name ${name.toUpperCase()}`);
      }
    });

  editName = input => {
    if (input !== this.state.name) this.editTeam(undefined, input);
  };

  editEmail = input =>
    new Promise(resolve => {
      if (input !== this.state.email) {
        if (input === '') {
          return this.editTeam(undefined, undefined, null);
        }
        return this.editTeam(undefined, undefined, input);
      }
      return resolve();
    });

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal
    });
  };

  renderMemberRow = (edge, index, adminTotalCount) => {
    const {
      admin: teamAdmin,
      collaborator,
      user: { rowId, email, name, avatar, pending, inviteExpired, id }
    } = edge;
    const {
      team: {
        canUpdate: canUpdateTeam,
        account: { members, rowId: teamAccountRowId },
        organization: { rowId: organizationRowId, id: organizationId },
        id: teamId
      },
      user: { canUpdate: canUpdateUser }
    } = this.props;

    /**
     * @constant isDisabled
     * As a superuser or an organization admin:
     *  - I can choose to toggle my own and other members' privileges.
     *  - At least one admin must be present at all times.
     *  - I can grant myself team admin privileges.
     *
     * As a sole team admin:
     *  - I can choose to toggle my own and other members' privileges.
     *  - At least one admin must be present at all times.
     *  - If I try to remove my own admin privileges, I will be warned
     *    by a confirmation modal.
     *    I will then loose all my rights for this team.
     */
    const isDisabled =
      !canUpdateTeam || !canUpdateUser || (teamAdmin && adminTotalCount === 1);

    return (
      <DirectoryRow
        key={rowId}
        isLast={index === members.totalCount - 1}
        main={
          <>
            <UserAvatarLink
              rowId={rowId}
              name={name || email}
              avatar={avatar}
              noLink={pending}
              collaborator={collaborator}
            />
          </>
        }
        cell1={
          <Switch
            label="role"
            on={teamAdmin}
            disabled={isDisabled}
            handleChange={() => this.switchRole(teamAdmin, rowId)}
          />
        }
        cell2={collaborator && <Tag text="Collaborators" />}
        cell3={
          canUpdateUser &&
          (pending && (
            <PendingInvite
              accountId={teamId}
              accountRowId={teamAccountRowId}
              connectionKey={TEAM_MEMBER_KEY}
              email={email}
              organizationId={organizationId}
              organizationRowId={organizationRowId}
              userId={id}
              userRowId={rowId}
              expired={inviteExpired}
            />
          ))
        }
      />
    );
  };

  renderBotRow = (edge, isLast) => {
    const { history, classes } = this.props;
    const { rowId, name, avatar } = edge;

    return (
      <DirectoryRow
        key={rowId}
        isLast={isLast}
        main={
          <Pushtext
            onClick={() =>
              history.push(pathToRegexp.compile(ROUTE_BOT)({ id: rowId }))
            }
          >
            <div className={classes.botLink}>
              <Avatar size={32} src={avatar} />
              <span className={classes.botName}>{name}</span>
            </div>
          </Pushtext>
        }
      />
    );
  };

  renderConfirmationModal = () => {
    const { user: { rowId } } = this.props;
    const { submitDisabled } = this.state;

    return (
      <Modal
        title="Remove from Team"
        handleCollapse={this.toggleModal}
        closeButtonLabel="Cancel"
      >
        <ModalContent>
          <Prose
            light
            text="You are attempting to remove your own admin privilege for this team. Only the remaining team admins can restore your privileges if you do this."
          />
        </ModalContent>
        <ModalActions>
          <Pushbutton onClick={this.toggleModal}>cancel</Pushbutton>
          <Pushbutton
            dataCy="confirm-revoke-admin"
            warning
            onClick={() => this.switchRole(true, rowId)}
            disabled={submitDisabled}
          >
            continue
          </Pushbutton>
        </ModalActions>
      </Modal>
    );
  };

  render() {
    const {
      classes,
      team: {
        avatar: teamAvatar,
        canUpdate: canUpdateTeam,
        account: { members },
        organization: { canUpdate: canUpdateOrganization },
        rowId,
        bots
      }
    } = this.props;
    const { name, email, showModal } = this.state;

    const adminTotalCount = members.edges.filter(member => member.node.admin)
      .length;

    const memberCount = pluralize('Member', members.totalCount);
    const botCount = pluralize('Bot', bots.totalCount);

    /* 
     * Only Organization owners and Team's admin can edit
     * the Team's name and avatar or delete it.
     */
    return (
      <>
        <div className={classes.sectionHeader}>
          <div className={classes.nameWrapper}>
            <>
              {canUpdateTeam ? (
                <UploadPhoto
                  size={58}
                  nth={Number(rowId)}
                  name={name}
                  avatar={teamAvatar}
                  getNewAvatar={this.editTeam}
                  overlayOnHover
                />
              ) : (
                <AvatarGroup
                  initials={nameToInitials(name)}
                  nth={Number(rowId)}
                  size={58}
                  src={teamAvatar}
                />
              )}
              <div className={classes.name}>
                {canUpdateTeam ? (
                  <EditableText
                    input={name}
                    placeholder="Team's name"
                    getInput={this.editName}
                  />
                ) : (
                  <span>{name}</span>
                )}
                <MailingList
                  canUpdateOrganization={canUpdateOrganization}
                  email={email}
                  onUpdate={this.editEmail}
                />
              </div>
            </>
          </div>
          {false && canUpdateTeam && <Pushtext mute>Delete Team...</Pushtext>}
        </div>
        <Directory
          showColumnTitles
          columnTitle={memberCount}
          columnTitle1={members.totalCount > 0 ? 'admin' : null}
        >
          {members.edges.map((edge, index) =>
            this.renderMemberRow(edge.node, index, adminTotalCount)
          )}
        </Directory>
        <div className={classes.botsDirectory}>
          <Directory showColumnTitles columnTitle={botCount}>
            {bots.edges.map((edge, index) =>
              this.renderBotRow(edge.node, index === bots.totalCount - 1)
            )}
          </Directory>
        </div>
        {showModal && this.renderConfirmationModal()}
      </>
    );
  }
}

export default createFragmentContainer(
  compose(
    withGqlClient,
    withRestClient,
    withRouter,
    withUser,
    withSnackbarsContext,
    injectSheet(styles)
  )(Team),
  {
    team: graphql`
      fragment team_team on Team
        @argumentDefinitions(
          memberFilter: { type: "MemberFilter" }
          botFilter: { type: "BotFilter" }
        ) {
        ...teamHeader_team
        id
        rowId # Field not used within the component but needed by updateTeam and editAccountMembershipTeam mutations licenses left.
        name
        email
        avatar
        canUpdate
        organization {
          id
          rowId
          organizationMemberLicensesLeft
          canUpdate
        }
        account {
          id
          rowId
          members(first: null, filter: $memberFilter)
            @connection(key: "Team_members", filters: []) {
            totalCount
            edges {
              node {
                admin
                collaborator
                userId # Field not used within the component but needed by editAccountMembershipTeam mutation.
                user {
                  id
                  rowId
                  email
                  name
                  avatar
                  pending
                  inviteExpired
                }
              }
            }
          }
        }
        bots(first: null, filter: $botFilter)
          @connection(key: "Team_bots", filters: []) {
          totalCount
          edges {
            node {
              rowId
              name
              avatar
            }
          }
        }
      }
    `
  }
);
