import React from 'react';
import PropType from 'prop-types';
import { withSnackbarsContext } from '@stratumn/atomic';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';

import { ROOT_API_URL } from 'constant/api';
import createEnvironment from './environment';

const GqlClientContext = React.createContext(() => {});

export const withGqlClient = Component => props => (
  <GqlClientContext.Consumer>
    {({ environment }) => <Component environment={environment} {...props} />}
  </GqlClientContext.Consumer>
);

class GqlClient extends React.PureComponent {
  static propTypes = {
    history: PropType.object.isRequired,
    errorSnackbar: PropType.func.isRequired,
    children: PropType.node.isRequired
  };

  constructor(props) {
    super(props);
    const { errorSnackbar, history } = props;
    this.environment = createEnvironment(ROOT_API_URL, errorSnackbar, history);
  }

  render() {
    return (
      <GqlClientContext.Provider value={{ environment: this.environment }}>
        {this.props.children}
      </GqlClientContext.Provider>
    );
  }
}

export default compose(withRouter, withSnackbarsContext)(GqlClient);
